import './App.css';
import Typist from 'react-typist';


var fonts = ['Roboto',
  'Open_Sans',
  'Raleway',
  'Rakkas',
  'Abril_Fatface',
  'Berkshire_Swash',
  'Arvo',
  'Spectral',
  'Germania_one',
  'Fredoka_one',
  'Space_mono',
  'Quantico'
]


function App() {

  return (
    <div className="App" >
      <link href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway|Roboto|Rakkas|Abril+Fatface|Berkshire+Swash|Arvo|Spectral|Germania+One|Fredoka+One|Space+Mono|Quantico" rel="stylesheet">
      </link>
      <header className="App-header">
        <div class="text">
          <h1 id="apology">Press spacebar <br></br>to apologize</h1>
          <div id="desc">
            <Typist>
              This project tries to hyperbolize the sociological phenomena of over-apologizing. Apologizing too quickly can come with social consequences—especially for women in a workplace. This is an exploration of an intersection between AI and graphic design. AIpology and visuality are generated on click of a spacebar - living in the moment in the times of corona. The project was supported by the city of Košice.
            </Typist>
            <br></br>
            <img id='imgs2' src='kosice.png' alt='Kosice'></img> &nbsp;&nbsp;&nbsp;
            <img id='imgs' src='cike.png' alt='Creative industry Kosice'></img>

          </div>


        </div>
      </header>
    </div >
  );
}

function getHex() {
  return '#' + Math.random().toString(16).slice(2, 8);
}

function setBackground() {
  var bgColor = getHex();
  document.body.style.background = bgColor;
}


document.body.onkeyup = function (a) {
  if (a.keyCode === 32) {
    document.getElementById("desc").classList.add('hidden');
    document.getElementById("imgs").classList.add('hidden');
    document.getElementById("imgs2").classList.add('hidden');
    const axios = require('axios');
    const FormData = require('form-data');
    var font = fonts[Math.floor(Math.random() * fonts.length)];
    var bgColor = getHex();
    var txtColor = invertColor(bgColor);
    const dataForm = new FormData();
    dataForm.append('font', this.font);
    dataForm.append('bgcolor', this.bgColor);
    dataForm.append('txtcolor', this.txtColor);
    console.log(font);
    console.log(bgColor);
    console.log(txtColor);

    axios.get('/generate', { crossDomain: true }).then(function (response) {
      //console.log(response);
      document.getElementById("apology").innerHTML = response.data;
      console.log(response.data);
      var stringLength = response.data.length;
      console.log(stringLength);
      if (stringLength > 50) {
        document.getElementById("apology").className = font;
        document.getElementById("apology").classList.add("smaller");
        console.log('String too long')
      }
      else {
        document.getElementById("apology").className = font;
        document.getElementById("apology").classList.remove("smaller");

      }
    }).then(function (response) {

      setBackground();
      document.getElementById("apology").style.color = txtColor;


    });

  }
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}
export default App;
